<template>
	<main>
		<Header></Header>
		<Overview></Overview>
		<Clients></Clients>
		<Partnership></Partnership>
		<Map></Map>
	</main>
</template>

<script>
import Header from './components/Header'
import Overview from './components/Overview'
import Clients from "@/components/Views/Home/components/Clients";
import Partnership from "@/components/Views/Home/components/Partnership";
import Map from "@/components/Views/Home/components/Map";
import {footerVisibility} from '@/mixins/globalMixins'
export default {
	name: 'Home',
	mixins: [footerVisibility],
	components: {
		Map,
		Partnership,
		Clients,
		Header,
		Overview
	},
	data() {
		return {}
	},
}
</script>