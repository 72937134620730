import { render } from "./Footer.vue?vue&type=template&id=0a56c92e&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"

import "./Footer.vue?vue&type=style&index=0&id=0a56c92e&lang=sass&scoped=true"
import "./Footer.vue?vue&type=style&index=1&id=0a56c92e&lang=sass&scoped=true"
import "./Footer.vue?vue&type=style&index=2&id=0a56c92e&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-0a56c92e"

export default script