<template>
	<section class="overview">
		<h2>Łączymy doświadczenie z praktyczną znajomością biznesu.</h2>

		<p>Świadczymy usługi prawne w licznych dziedzinach, w szczególności specjalizujemy się w prawie nowoczesnych
			technologii, prawie własności intelektualnej i prawie autorskim, ochronie danych osobowych (RODO), prawie
			spółek handlowych, prawie korporacyjnym, jak również w prawie pracy.</p>

		<router-link to="/contact">
			<Button
					buttonStyle="primary"
					buttonSize="large"
					buttonText="Skontakuj się z nami"
					:icon="true"
			>
			</Button>
		</router-link>
	</section>
</template>

<script>
export default {
	name: 'Overview',
	functional: true,
}
</script>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

.overview
	width: 100%
	text-align: center
	padding: 75px 270px

	h2
		margin: 25px 0
		font-family: $font--primary
		font-size: 2.6rem
		font-weight: 500

	p
		font:
			family: $font--default
			size: 1.7rem
			weight: 300
		line-height: 34px
		margin-bottom: 50px
</style>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

@media screen and (min-width: 320px) and (max-width: 980px)

	.overview
		width: 100vw
		padding: 50px 20px

		h2
			font-size: 1.5rem

		p
			line-height: 19px
			font-size: 1.3rem
			padding: 0 40px
</style>