<template>
	<div class="offer__economic">
		<h2>Prawo Gospodarcze</h2>

		<ul class="offer__list">
			<li class="offer__item" v-for="link in ecoItems" :id='link.id'>
				<router-link class="offer__link" :to="link.path">
					{{ link.name }}
					<i class="fas fa-arrow-right"></i>
				</router-link>
			</li>
		</ul>
	</div>

	<div class="offer__labor">
		<h2>prawo pracy</h2>

		<ul class="offer__list">
			<li class="offer__item" v-for="link in laborItems" :key="link.id">
				<router-link class="offer__link" :to="link.path">
					{{ link.name }}
					<i class="fas fa-arrow-right"></i>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import {prepareRouterLinks} from '@/mixins/globalMixins'

export default {
	name: "eco",
	mixins: [prepareRouterLinks],
	computed: {
		ecoItems() {
			return this.globalRouterLinks.slice(5, 15)
		},
		laborItems() {
			return this.globalRouterLinks.slice(15, 21)
		}
	},
	methods: {
		emitEvent() {
			this.$emit('show')
		}
	},
	data() {
		return {}
	},
}
</script>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'
.fa-arrow-right
	display: none

.offer
	&__economic, &__labor
		padding-left: 101px

		h2
			position: relative
			text-transform: uppercase
			font:
				size: 2.8rem
				family: $font--primary
				weight: 700
			line-height: 37px

			&::after
				position: absolute
				content: ''
				top: 50%
				right: 20%
				width: 15em
				height: 1px
				background: gray
	
	&__economic, &__labor
		width: 100%
		height: 50%
		display: flex
		flex-direction: column

	&__labor
		margin-top: 50px

		h2
			&::after
				width: 55%

	&__list
		height: 100%
		width: 75%
		display: flex
		padding-top: 30px
		flex-direction: row
		align-items: center
		flex-wrap: wrap
		justify-content: space-between

	&__item
		display: flex
		align-items: center
		width: 40%
		height: 15%

	&__link
		transition: all .1s ease
		transform: scale(1)
		letter-spacing: 0

		font:
			size: 1.9rem
			family: $font--primary
			weight: 400
		line-height: 26px

		&:hover
			transform: scale(1.01)
			font-weight: 700
</style>

<style lang="sass" scoped>
@media screen and (min-width: 320px) and (max-width: 980px)

	.offer

		&__economic, &__labor
			padding: 0 30px
			margin: 20px 0

			h2
				font-size: 1.7rem
				text-align: center
				margin-bottom: 20px

				&::after
					display: none

		&__list
			width: 100%
			flex-direction: column
			align-items: flex-start
			justify-content: space-evenly

		&__item
			width: 100%
			flex-direction: row
			padding: 15px 0

			a
				font-size: 1.2rem
				width: 100%
				display: flex
				justify-content: space-between
				align-items: center

			.fa-arrow-right
				display: block
</style>