import {createRouter, createWebHistory} from 'vue-router'
import Corp from '../components/Views/Offer/components/EconomicPages/Corporation.vue'
import Mergers from '../components/Views/Offer/components/EconomicPages/Mergers.vue'

const routes = [
	{
		path: '/',
		name: 'name',
		component: () => import('../components/Views/Home/Home.vue'),
	},
	{
		path: '/offer',
		name: 'Oferta',
		component: () => import('../components/Views/Offer/OfferApp.vue'),
	},
	{
		path: '/about',
		name: 'O nas',
		component: () => import('../components/Views/About/About.vue')
	},
	{
		path: '/',
		name: 'Blog',
		component: () => import('../components/Views/Home/Home.vue')
	},
	{
		path: '/contact',
		name: 'Kontakt',
		component: () => import('../components/Views/Contact/Contact.vue')
	},


	//economics
	{
		path: '/corp',
		name: 'Obsługa korporacyjna',
		component: () => import('../components/Views/Offer/components/EconomicPages/Corporation.vue')
	},
	{
		path: '/mergers',
		name: 'Fuzje i przejęcia',
		component: () => import('../components/Views/Offer/components/EconomicPages/Mergers.vue')
	},
	{
		path: '/succession',
		name: 'Sukcesja w firmach',
		component: () => import('../components/Views/Offer/components/EconomicPages/SuccessionInCompanies')
	},
	{
		path: '/venture',
		name: 'Venture Capital & Privater Equity',
		component: () => import('../components/Views/Offer/components/EconomicPages/VentureCapital')
	},
	{
		path: '/tech',
		name: 'Nowe technologie i branża IT',
		component: () => import('../components/Views/Offer/components/EconomicPages/IT.vue')
	},
	{
		path: '/creative',
		name: 'Branża kreatywna',
		component: () => import('../components/Views/Offer/components/EconomicPages/CreativeIndustry')
	},
	{
		path: '/ecommerce',
		name: 'E-commerce',
		component: () => import('../components/Views/Offer/components/EconomicPages/E-Commerce')
	},
	{
		path: '/intellectual',
		name: 'Własność intelektualna i prace B+R',
		component: () => import('../components/Views/Offer/components/EconomicPages/IntellectualProperty')
	},
	{
		path: '/startups',
		name: 'Startupy',
		component: () => import('../components/Views/Offer/components/EconomicPages/Startups')
	},
	{
		path: '/personaldata',
		name: 'Dane osobowe',
		component: () => import('../components/Views/Offer/components/EconomicPages/PersonalData')
	},

	//Labor
	{
		path: '/collective',
		name: 'Zbiorowe prawo pracy',
		component: () => import('../components/Views/Offer/components/LaborPages/CollectiveLaborLaw'),
	},
	{
		path: '/unions',
		name: 'Pomoc prawna w relacji Pracodawca – Związki Zawodowe',
		component: () => import('../components/Views/Offer/components/LaborPages/Unions'),
	},
	{
		path: '/procedural',
		name: 'Procesowe prawo pracy / reprezentacja przed organami',
		component: () => import('../components/Views/Offer/components/LaborPages/ProceduralLaborLaw'),
	},
	{
		path: '/socialsecurity',
		name: 'Prawo ubezpieczeń społecznych',
		component: () => import('../components/Views/Offer/components/LaborPages/SocialSecurityLaw'),
	},
	{
		path: '/individual',
		name: 'Indywidualne prawo pracy',
		component: () => import('../components/Views/Offer/components/LaborPages/IndividualLaborLaw'),
	},
	{
		path: '/training',
		name: 'Szkolenia',
		component: () => import('../components/Views/Offer/components/LaborPages/Training'),
	},
]

const router = createRouter({
	history: createWebHistory(),
	mode: 'hash',
	routes,
})

export default router
