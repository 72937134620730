<script>
const person = [
	{
		id: 1,
		law: 'Prawo Pracy',
		fullName: 'Izabela Zawacka',
		text1: 'Radca prawny w Okręgowej Izbie Radców Prawnych w Warszawie (WA-7380), adwokat w Okręgowej Radzie Adwokackiej w Warszawie, absolwentka Wydziału Prawa i Administracji Uniwersytetu Warszawskiego, które ukończyła z wyróżnieniem, doktorantka w Katedrze Ubezpieczeń na Wydziale Prawa i Administracji Uniwersytetu Warszawskiego, od 2014 roku wykładowca prawa pracy w Okręgowej Izbie Radców Prawnych w Warszawie, w latach 2006-2019 współpracownik i wspólnik jednej z wiodących kancelarii prawa pracy, gdzie pełniła funkcję Lidera Działu Zbiorowego Prawa Pracy. W 2018 roku została wyróżniona w międzynarodowym rankingu The Legal 500 EMEA 2018, otrzymując tytuł recommended lawyer 2018, wyłaniany wyłącznie ze względu na kryteria merytoryczne. Coroczne zestawienia przygotowywane są na podstawie tysięcy wywiadów przeprowadzanych przez analityków z klientami kancelarii oraz konkurencyjnymi firmami prawniczymi.',

		text2: 'Radca prawny Izabela Zawacka jest prawnikiem posiadającym specjalizację zawodową - posiada 14 letnie doświadczenie zawodowe w obszarze szeroko rozumianego prawa pracy, w tym 11 letnie doświadczenie zawodowe jako radca prawny ',

		text3: 'Od 2005 roku nieprzerwanie specjalizuje się wyłącznie w pomocy pracodawcom w codziennych relacjach z pracownikami i ich przedstawicielstwami, takimi jak związki zawodowe i rady pracowników z zakresie szeroko rozumianego zbiorowego i indywidualnego prawa pracy. Posiada bogate doświadczenie praktyczne w obszarach dotyczących m.in. postępowań sądowych w indywidualnych sprawach pracowniczych, zwolnień grupowych, indywidualnych stosunków pracy, a także negocjowania wewnątrzzakładowych aktów prawa pracy. Doradzała jednym z największych pracodawców w Polsce, w tym w Spółkach z udziałem Skarbu Państwa z udziałem działających tam organizacji związkowych w zakresie bieżącej obsługi prawnej, zastępstwa sądowego oraz projektów restrukturyzacyjnych (przejęcia, przygotowanie strategii i obsługa procesu przejścia całości/wydzielonej części zakładu do nowego pracodawcy, restrukturyzacje, zwolnienia grupowe, programy dobrowolnych odejść, zmiana systemów wynagradzania, negocjowanie/tworzenie/wypowiadanie układów zbiorowych pracy oraz innych zakładowych źródeł prawa pracy, prowadzenie sporów zbiorowych).',

		text4: 'Autorka licznych artykułów prasowych w Rzeczpospolitej oraz Gazecie Prawnej, autorka pozycji książkowych. Współpracuje między innymi z Gazetą Prawną i Rzeczpospolitą, a także wydawnictwami C.H. Beck oraz INFOR Biznes. ',
		image: require('../../../assets/images/about/iza.png')
	},
	{
		id: 2,
		law: 'Prawo Gospodarcze',
		fullName: 'Łukasz Rdzeń',
		text1: 'Specjalizuje się w obszarze szeroko rozumianego prawa gospodarczego nieprzerwanie od 2003 r.',
		text2: 'Radca prawny odpowiedzialny za dział Prawo Przedsiębiorstw, świadczący pomoc prawną dla podmiotów gospodarczych z branży nowych technologii, reklamowej, e-commerce, a także funduszy inwestycyjnych i startupów.',
		text3: 'Wieloletnią praktykę pracy w kancelarii prawnej w obszarze prawa gospodarczego łączy z doświadczeniem zdobytym jako in-house lawyer agencji reklamowej oraz szef działu prawnego jednego z wiodących polskich portali internetowych.',
		image: require('../../../assets/images/about/lukasz.png')
	},
	// {
	// 	id: 3,
	// 	law: 'Prawo Pracy',
	// 	fullName: 'Małgorzata Małecka',
	// 	text1: 'Posiada wieloletnie doświadczenie w prowadzeniu spraw sądowych z zakresu prawa pracy, przygotowywaniu dokumentów zakładowych dla pracodawców (regulaminy pracy, regulaminy wynagradzania i inne wewnętrzne procedury np. procedury antymobbingowe), jak również w prowadzeniu negocjacji oraz mediacji pomiędzy pracodawcami a organizacjami związkowymi. \n' +
	// 		'Doświadczenie zdobywała zapewniając obsługę prawną jednostkom odpowiedzialnym za pomoc medyczną w skali całej Polski, a także Pracownikom z branży mediowej. Była członkiem Zarządu \n' +
	// 		'w Eurozet Consulting sp. z o.o. odpowiedzialnym za sprawy pracownicze.',
	// 	text2: 'Prowadzi szkolenia z prawa pracy dla kadr zarządzających. Radca prawny w Okręgowej Izbie Radców Prawnych w Warszawie.',
	// 	image: require('../../../assets/images/about/malgorzata.png')
	// },
	{
		id: 4,
		law: 'Prawo Gospodarcze',
		fullName: 'Michał Piekarz',
		text1: 'Posiada wieloletnie doświadczenie w obsłudze przedsiębiorców zdobyte zarówno w kancelariach prawnych, a także jako delegowany in-house lawyer. Specjalizuje się w bieżącej obsłudze przedsiębiorców we wszelkich aspektach ich działalności, zarówno na gruncie szeroko pojętego prawa cywilnego, jak i administracyjnego. Posiada bogatą wiedzę i praktyczne doświadczenie w realizacji złożonych projektów inwestycyjnych z udziałem podmiotów krajowych, jak i zagranicznych, w szczególności w zakresie tworzenia i negocjowania umów inwestycyjnych, tworzenia struktur grup kapitałowych, przekształceń i połączeń.',
		text2: 'Odpowiedzialny za bieżącą obsługę prawną spółek technologicznych i startupów w zakresie prawa kontraktów, prawa korporacyjnego oraz prawa własności intelektualnej. Aplikant radcowski w Okręgowej Izbie Radców Prawny w Warszawie. Absolwent studiów prawniczych na Uniwersytecie Kardynała Stefana Wyszyńskiego w Warszawie, które ukończył z wyróżnieniem.',
		image: require('../../../assets/images/about/michal.png')
	},
	{
		id: 5,
		law: 'BEZPIECZEŃSTWO INFORMACJI I DANYCH OSOBOWYCH',
		fullName: 'Monika Kamińska',
		text1: 'Ochroną danych zajmuje się od ponad 7 lat. Doświadczenie w obszarze bezpieczeństwa informacji \n' +
			'i ochrony danych osobowych zdobywała w dziale bezpieczeństwa w jednej z największych firm \n' +
			'z branży internetowej.',
		text2: 'Obecnie audytor, konsultant i trener, wspierający podmiotami z sektora finansowego i prywatnego \n' +
			'w spełnieniu wymagań RODO oraz wdrożeniu systemu zarządzania bezpieczeństwem informacji. \n' +
			'Pełni funkcję zewnętrznego Inspektora Ochrony Danych w podmiotach z sektora finansowego \n' +
			'i prywatnego. Współpracuje z Instytutem Audytorów Wewnętrznych IIA Polska. ',
		image: require('../../../assets/images/about/monika.png')
	},

]

export default person
</script>
