<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/mergers.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/mergers.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Fuzje i przejęcia</h2>
		</template>

		<template #text>
			<p>
				Świadczymy kompleksowe usługi związane z transakcją, począwszy od czynności przygotowawczych po wsparcie
				postransakcyjne.
			</p>

			<p>
				Kancelaria prowadzi kompleksową obsługę prawną fuzji, przejęć oraz restrukturyzacji i przekształceń
				podmiotów gospodarczych, w tym transakcji obejmujących przejęcie zorganizowanej części przedsiębiorstwa,
				jak również obsługę prawną nabywania i zbywania aktywów.
			</p>

			<p>
				Przygotowujemy i negocjujemy umowy, harmonogramy, prowadzimy postępowania związane z fuzjami i
				przejęciami, prowadzimy kompleksową dokumentację transakcji oraz pomagamy w negocjacjach. Dopasowujemy
				rozwiązania prawne, proponując działania, które pozwalają na osiągnięcie celów biznesowych Klientów przy
				jednoczesnym zminimalizowaniu ryzyka wystąpienia ewentualnych sporów.
			</p>

			<p>
				Wspomagamy udziałowców, akcjonariuszy i inwestorów, a także duże podmioty, w tym fundusze Private
				Equity/Venture Capital, jak i przedsiębiorstwa o charakterze rodzinnym.
			</p>

		</template>

		<template #range>
			<h3>ZAKRES:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Przygotowanie prawnej struktury transakcji</li>
				<li class="offer__list-item">Badanie due diligence,</li>
				<li class="offer__list-item">Negocjacje umów,</li>
				<li class="offer__list-item">Wsparcie postransakcyjne,</li>
				<li class="offer__list-item">Przygotowanie dokumentacji transakcyjnej,</li>
				<li class="offer__list-item">Opracowanie optymalnego modelu dla transakcji</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'Mergers',
}
</script>