<template>
	<section class="map">
	</section>
</template>

<script>
export default {
	name: 'Map',
	functional: true,
}
</script>

<style lang="sass" scoped>
.map
	position: relative
	height: 36em
	background-image: url("../../../../assets/images/map.png")
	background-repeat: no-repeat
	background-size: 100% 100%

.marker
	position: absolute
	top: 30%
	left: 49%
</style>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

@media screen and (min-width: 320px) and (max-width: 980px)
	.map
		width: 100vw
		height: 24.6em
		background-image: url("../../../../assets/images/mobile/map.png")

@media screen and (min-width: 768px) and (min-height: 1024px)

	.map
			width: 70vw
			margin: auto
			height: 24.6em
			background-image: url("../../../../assets/images/mobile/map.png")
</style>