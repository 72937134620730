import { render } from "./Partnership.vue?vue&type=template&id=8cba2e18&scoped=true"
import script from "./Partnership.vue?vue&type=script&lang=js"
export * from "./Partnership.vue?vue&type=script&lang=js"

import "./Partnership.vue?vue&type=style&index=0&id=8cba2e18&lang=sass"
import "./Partnership.vue?vue&type=style&index=1&id=8cba2e18&lang=sass&scoped=true"
import "./Partnership.vue?vue&type=style&index=2&id=8cba2e18&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-8cba2e18"

export default script