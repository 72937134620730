<template>
	<footer class="footer" :footerStyle="footerStyle">
		<div class="footer__content" v-if="footerStyle === 'default'">
			<div class="footer__logo">
				<img src="../../assets/images/logo.svg" alt="logo">
			</div>

			<div class="footer__contact">
				<p class="footer__phone">tel. 570 769 115</p>
				<p class="footer__email">kancelaria@zawacka-rdzen.pl</p>
			</div>

			<div class="footer__address">
				<p>ul. Sarmacka 1A lok 145</p>
				<p>02-972 Warszawa</p>
			</div>
		</div>

		<div class="footer__content sub--footer" v-if="footerStyle === 'offerFooter' && mobile === false">

			<div class="sub--footer-view">
				<div class="footer__logo">
					<img src="../../assets/images/logo.svg" alt="logo">
				</div>

				<div class="footer__address">
					<p>ul. Sarmacka 1A lok 145</p>
					<p>02-972 Warszawa</p>
				</div>

<!--				<div class="footer__contact">-->
<!--					<p class="footer__phone">tel. 570 769 115</p>-->
<!--					<p class="footer__email">kancelaria@zawacka-rdzen.pl</p>-->
<!--				</div>-->
			</div>

			<div class="contact__info">
				<div class="law" v-if="labor === true">
					<h2>Więcej informacji o prawie pracy</h2>
					<h3>tel. 724 555 044</h3>
				</div>

				<div class="law" v-if="economic === true">
					<h2>Więcej informacji o prawie gospodarczym</h2>
					<h3>tel. 601 150 221</h3>
				</div>
			</div>
		</div>

		<div class="footer__content sub--footer sub--footer--mobile"
		     v-if="footerStyle === 'offerFooter' && mobile === true">

			<div class="contact__info">
				<div class="law" v-if="labor === true">
					<h2>Więcej informacji o prawie pracy</h2>
					<h3>tel. 724 555 044</h3>
				</div>

				<div class="law" v-if="economic === true">
					<h2>Więcej informacji o prawie gospodarczym</h2>
					<h3>tel. 601 150 221</h3>
				</div>
			</div>

			<div class="sub--footer--mobile-view">
				<div class="footer__logo">
					<img src="../../assets/images/logo.svg" alt="logo">
				</div>

<!--				<div class="footer__contact">-->
<!--					<p class="footer__phone">tel. 570 769 115</p>-->
<!--					<p class="footer__email">kancelaria@zawacka-rdzen.pl</p>-->
<!--				</div>-->

				<div class="footer__address">
					<p>ul. Sarmacka 1A lok 145</p>
					<p>02-972 Warszawa</p>
				</div>
			</div>


		</div>
	</footer>
</template>

<script>
import {handleWith} from "@/mixins/globalMixins";

export default {
	name: 'Footer',
	functional: true,
	mixins: [handleWith],
	props: {
		footerStyle: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			labor: false,
			economic: false,
		}
	},
	watch: {
		footerStyle: function () {
			if (this.footerStyle === 'offerFooter')
				this.test()

			if (this.footerStyle === 'default') {
				this.economic = false
				this.labor = false
			}
		}
	},
	methods: {
		test() {
			const smallTitleContent = document.querySelector('.offer__box-overview h4')

			if (
				smallTitleContent.firstChild.nodeValue === 'prawo gospodarcze'
				||
				smallTitleContent.firstChild.nodeValue === 'PRAWO GOSPODARCZE'
			) {
				this.economic = true
				this.labor = false
			} else if (smallTitleContent.firstChild.nodeValue === 'PRAWO PRACY') {
				this.economic = false
				this.labor = true
			}
		}
	}
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/variables'

.sub--footer
	display: flex
	flex-direction: row
	justify-content: space-between !important
	padding-right: 150px

	&-view
		height: 100%
		width: 25vw
		display: flex
		flex-direction: column
		justify-content: space-evenly
		align-items: flex-start
		padding-left: 100px
		padding-bottom: 50px

		.footer__contact
			text-align: left
			width: 100%
			padding: 0

		.footer__address
			text-align: left
			margin-bottom: 10px

			p
				font-weight: 300

	.contact__info
		text-align: right
		margin-bottom: 100px

		h2
			padding-bottom: 15px
			font:
				size: 2.5rem
				weight: 300

		h3
			font:
				size: 3.2rem
				family: $font--primary
				weight: 700
</style>

<style lang="sass" scoped>
@import '../../assets/sass/variables'

.footer :is(p)
	font-weight: 700
	font-size: 1.5rem

.footer
	position: relative
	min-height: 25vh

	&::before
		position: absolute
		content: ''
		width: 100%
		height: 16px
		bottom: 0
		left: 0
		background: $color--accent

	&__content
		width: 100%
		display: flex
		flex-direction: row
		justify-content: space-evenly
		align-items: center

	&__logo
		width: 15em

		img
			width: 100%

	&__contact
		width: 65em
		padding-left: 50px

		p
			padding: 5px 0

	&__address
		text-align: right
		width: 20em

		p
			padding: 5px 0

</style>

<style lang="sass" scoped>
@import '../../assets/sass/variables'

@media screen and (min-width: 768px) and (min-height: 1024px)
	.footer
		min-height: 20vh

		&__contact
			width: 30em

@media screen and (min-width: 360px) and (max-width: 980px)
	.footer
		width: 100vw
		height: auto
		padding: 25px 0

		&__content
			flex-direction: column
			padding-bottom: 20px
			height: 100%

		&__logo
			width: 9em

		&__contact
			width: 100vw
			padding: 0
			text-align: center
			margin: 10px 0

			p
				font-size: 1.2rem
				line-height: 22px

		&__address
			margin: 10px 0
			text-align: center

			p
				font-size: 1.2rem
				line-height: 22px


		//sub footer mobile

		.sub--footer--mobile
			padding-right: 0
			padding-bottom: 50px

			&-view
				width: 100%
				padding: 0
				display: flex
				flex-direction: column
				align-items: center

			.contact__info
				margin-bottom: 40px

				.law
					position: relative
					text-align: center

					&::before, &::after
						position: absolute
						content: ''
						left: 50%
						width: 50%
						height: 1px
						transform: translateX(-50%)
						background: rgba(0, 0, 0, .5)

					&::before
						top: -20px

					&::after
						bottom: -20px

					h2
						font-size: 1.4rem
						line-height: 27px
						margin-bottom: 10px

					h3
						line-height: 26px
						font-size: 2.1rem

</style>